<template>
    <div class="c-widget--text o-container" v-html="data.text"></div>
</template>

<script>
    export default {
        name: 'PostText',
        props: {
            data: Object
        },
    }
</script>

<style scoped>

</style>